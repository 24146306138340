import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ellipsis = _resolveComponent("ellipsis")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_question_filled = _resolveComponent("question-filled")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_lx_crud = _resolveComponent("lx-crud")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lx_crud, {
      ref: "crud",
      options: _ctx.options,
      immediate: "",
      pagination: ""
    }, {
      work_user_bugsTableSlot: _withCtx((scope) => [
        (scope.row.is_no_bug)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 无bug "))
          : (_openBlock(), _createBlock(_component_ellipsis, {
              key: 1,
              index: scope.$index,
              content: _ctx.loadBugsOverview(scope.row)
            }, {
              lxOverPopupContent: _withCtx(() => [
                _createVNode(_component_el_table, {
                  data: scope.row.work_user_bugs
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      width: "60",
                      property: "stageLabel",
                      label: "阶段"
                    }),
                    _createVNode(_component_el_table_column, {
                      width: "60",
                      property: "levelLabel",
                      label: "等级"
                    }),
                    _createVNode(_component_el_table_column, {
                      width: "60",
                      property: "convertCount",
                      label: "数量"
                    })
                  ]),
                  _: 2
                }, 1032, ["data"])
              ]),
              _: 2
            }, 1032, ["index", "content"]))
      ]),
      work_nameTableSlot: _withCtx((scope) => [
        _createVNode(_component_el_button, {
          type: "text",
          disabled: !scope.row.work_id,
          onClick: ($event: any) => (_ctx.goTask(scope.row.work_id))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(scope.row.work_name), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "onClick"])
      ]),
      degree_of_difficultTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.degree_of_difficult ? `${scope.row.degree_of_difficult / 10}` : '--'), 1)
      ]),
      bug_scoreTableSlotHeader: _withCtx(() => [
        _createVNode(_component_el_popover, {
          visible: _ctx.tipsControl.visible,
          "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipsControl.visible) = $event)),
          placement: "top",
          width: 400,
          trigger: "manual"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createTextVNode(" bug_score ")),
              _createVNode(_component_el_icon, {
                size: 15,
                class: "can-pointer",
                onClick: _ctx.openTipsPopup
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_question_filled)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              innerHTML: _ctx.tipsControl.content
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["visible"])
      ]),
      bug_scoreTableSlot: _withCtx((scope) => [
        _createElementVNode("div", null, _toDisplayString(scope.row.bug_score ? `${scope.row.bug_score}（${scope.row.bug_score_kpi}）` : '--'), 1)
      ]),
      _: 1
    }, 8, ["options"])
  ]))
}