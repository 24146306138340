
import {
  defineComponent, onMounted, ref, Ref,
} from 'vue';

export default defineComponent({
  name: 'Ellipsis',
  props: {
    content: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    overType: {
      type: String,
      default: 'popover',
    },
    trigger: {
      type: String,
      default: 'click',
    },
    placement: {
      type: String,
      default: 'left',
    },
  },
  emits: ['emitClick'],
  setup(props, context) {
    const propsContent = ref(props.content);
    const isEllipsis = ref(false);
    // 复制相关样式
    function copyStyle(elem: HTMLElement) {
      const needStyle = ['fontSize', 'fontFamily', 'padding', 'border', 'fontWeight', 'color'];
      const computedStyle: CSSStyleDeclaration = window.getComputedStyle(elem, null);
      return needStyle.map(style => ({
        name: style,
        value: computedStyle[style as keyof CSSStyleDeclaration],
      }));
    }

    // 判断文本是否溢出
    function checkEllipsis(elem: HTMLElement) {
      const old = elem.getBoundingClientRect().width;
      const newNode: HTMLElement | any = elem.cloneNode(true);
      const result = copyStyle(elem);
      newNode.style.width = 'auto';
      newNode.style.visibility = 'visible';
      newNode.style.display = 'inline-block';
      result.forEach(item => {
        newNode.style[item.name as any] = item.value;
      });
      document.body.appendChild(newNode);
      const now = newNode.getBoundingClientRect().width;
      if (Math.abs(old - now) > 1) {
        // 溢出了
        newNode.remove();
        return true;
      }
      newNode.remove();
      return false;
    }

    // 更新文本数据
    function updateEl (selectEl: HTMLElement | null, isEl: Ref<boolean>) {
      if (!selectEl) return;
      isEl.value = checkEllipsis(selectEl);
      isEl.value && (selectEl.className += ' over-views');
    }

    // 需要超出隐藏并设置为回调点击时
    const emitClick = () => {
      context.emit('emitClick');
    };
    onMounted(() => {
      updateEl(document.getElementById(`lxOverPopup${props.index}`), isEllipsis);
    });
    return {
      propsContent,
      isEllipsis,
      emitClick,
    };
  },
});
