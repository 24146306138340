
import {
  getStage, getStageList, updateStage, getPlanEmail, getUser,
} from '@/api';
import { Message } from '@/common/utils';
import { HeaderRow } from '@/types/task/header';
import { Stage, StageRow } from '@/types/task/stage';
import { Member } from '@/types/task/member';
import {
  defineComponent, getCurrentInstance, onMounted, reactive, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useFormRequired } from '@/hooks/useFormRequired';
import { useHeaderRows } from '@/hooks/useHeaderRows';
import { useStore } from 'vuex';
import { useDateFormat } from '@/hooks/useDateFormat';
import useClipboard from 'vue-clipboard3';
import { htmlToText } from 'html-to-text';
import StageTable from './components/stage-table.vue';
import DetailHeader from './components/detail-header.vue';

export default defineComponent({
  components: {
    DetailHeader,
    StageTable,
  },
  setup() {
    const { toClipboard } = useClipboard();
    const store = useStore();
    const headerRows = ref<Array<HeaderRow>>([]); // 页头迭代信息
    const addForm = ref({
      stage_id: null as number | null,
      stage_name: '' as string,
      started_at: null as Date | null,
      finished_at: null as Date | null,
      principal_id: null as number | null,
      principal_name: '' as string,
    }); // 新增阶段表单字段
    const [memberArr, stageArr] = [ref<Array<Member & { value: string }>>([]), ref<Array<Stage & { value: string }>>([])];
    const [copyText, copyLoading] = [ref(''), ref(false)];
    const erroStr = '请从下拉选择中添加';
    // 过滤是否从下拉选择中获取阶段信息
    const filterStage = (rule: any, value: string, callback: (...arg: any) => any) => {
      const index = stageArr.value.findIndex(stage => stage.value === value);
      if (value === '') {
        callback(new Error('请添加阶段'));
      } else if (index === -1) {
        callback(new Error('请从下拉选择中添加阶段'));
      } else {
        callback();
      }
    };
    // 过滤是否从下拉选择中获取负责人信息
    const filterPrincipalName = (rule: any, value: string, callback: (...arg: any) => any) => {
      const index = memberArr.value.findIndex(principal => principal.value === value);
      if (value === '') {
        callback(new Error('请添加负责人'));
      } else if (index === -1) {
        callback(new Error(`${erroStr}负责人`));
      } else {
        callback();
      }
    };
    const filterPrincipalId = (rule: any, value: string, callback: (...arg: any) => any) => {
      const findMember = memberArr.value.findIndex(member => member.value === addForm.value.principal_name);
      if (addForm.value.principal_name && findMember === -1) {
        callback(new Error(`${erroStr}负责人`));
      } else {
        callback();
      }
    };
    const isRequired = useFormRequired({
      started_at: '开始时间',
      finished_at: '结束时间',
    });
    // form表单规则
    const rules = reactive({
      ...isRequired,
      stage_name: [
        { validator: filterStage, required: true },
      ],
      principal_name: [
        { validator: filterPrincipalName, required: true },
      ],
      principal_id: [{ validator: filterPrincipalId, required: false }],
    });
    const route = useRoute();
    const workId = route.query.id || ''; // 迭代id
    const tableData = ref<Array<StageRow>>([]);
    const status = ref('active');
    const tabs = [
      { label: '正常', name: 'active' },
      { label: '已删除', name: 'deleted' },
    ]; // 渲染tabs数组

    // 搜索阶段列表
    const search = async () => {
      const { code, data } = await getStageList(workId, { status: status.value });
      if (code === 0) {
        tableData.value = data.stages;
        headerRows.value = useHeaderRows(data.work, ['follow', 'member', 'create', 'taskBug']);
      }
    };

    const queryParams = reactive({
      page: 1,
      count: 10,
    }); // 模糊搜索字段

    // 获取模糊匹配阶段信息
    const queryStage = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getStage({ ...queryParams, name });
      if (code === 0) {
        // 缓存起来 用于表单校验
        stageArr.value = data.stages.map(stage => ({
          ...stage,
          value: stage.name,
        }));
        cb(stageArr.value);
      } else {
        Message('获取阶段失败', 'error');
      }
    };

    /*
    * memberArr：模糊匹配用户列表
    */
    const queryMember = async (name: string, cb: (...args: any) => any) => {
      const { code, data } = await getUser({
        ...queryParams,
        name: name === 'null' ? '' : name,
        status: 'active',
      });
      if (code === 0) {
        // 缓存起来 用于表单校验
        memberArr.value = data.users.map(member => ({
          ...member,
          value: member.name,
        }));
        cb(memberArr.value);
      } else {
        Message('获取用户失败', 'error');
      }
    };

    const handleMemberSelect = (item: Member) => {
      // 在没有相关模糊搜索下 将id置为null
      addForm.value.principal_id = item.id || null;
    };

    const handleStageSelect = (item: Stage) => {
      // 在没有相关模糊搜索下 将id置为null
      addForm.value.stage_id = item.id || null;
    };

    const visible = ref(false); // 新增阶段弹框

    // 新增阶段
    const addStage = async () => {
      const {
        stage_id, started_at: started, finished_at: finished, principal_id,
      } = addForm.value;

      if ((finished ?? 0) < (started ?? 1)) {
        Message('结束时间应大于等于开始时间', 'error');
        return;
      }
      const started_at = useDateFormat(started);
      const finished_at = useDateFormat(finished);
      const options = finished_at ? {
        stage_id, started_at, finished_at, principal_id,
      } : { stage_id, started_at, principal_id };
      const { code } = await updateStage(workId, [options]);
      if (code === 0) {
        Message('新增阶段成功');
        visible.value = false;
        search();
      }
    };
    const ctx = getCurrentInstance()?.proxy;
    // form表单校验
    const submit = () => {
      (ctx?.$refs.form as any).validate((valid: any) => {
        if (valid) {
          addStage();
        } else {
          return false;
        }
      });
    };

    // 如果结束时间为空 则选择开始时间时 自动填充结束时间
    const handleStartPicker = (e: Date) => {
      if (!addForm.value.finished_at) {
        addForm.value = {
          ...addForm.value,
          finished_at: e,
        };
      }
    };

    // 复制排期
    const handleCopyPlan = async () => {
      if (copyLoading.value) return;
      copyLoading.value = true;
      try {
        const { code, data } = await getPlanEmail(workId);
        if (code === 0) {
          copyText.value = htmlToText(data.plan_email, { wordwrap: 130 }) as string;
          const handleCopy = async (text: string) => {
            await toClipboard(text);
          };
          await handleCopy(copyText.value);
          Message('复制成功');
        } else {
          Message('复制失败', 'error');
        }
      } catch (err) {
        Message(`复制失败：${err}`, 'error');
      } finally {
        copyLoading.value = false;
      }
    };

    // 关闭弹出框时清空表单
    watch(visible, (val) => {
      if (!val) {
        // principal_name 字段通过resetFields方法没生效，所以做了强制去空，有谁找到原因了记得告诉我下
        addForm.value.principal_name = '';
        (ctx?.$refs.form as any).resetFields();
      }
    });

    watch(() => addForm.value.principal_name, (nVal) => {
      if (!nVal) {
        addForm.value.principal_id = null;
      }
    });

    onMounted(() => {
      search();
      memberArr.value = [
        ...memberArr.value,
        {
          id: store.getters['user/getCurrentUserDetail'].id,
          name: store.getters['user/getCurrentUserDetail'].name,
          value: store.getters['user/getCurrentUserDetail'].name,
          avatar: '',
          email: '',
          mobile_number: 0,
          type: '',
          type_code: '',
        },
      ];
    });

    return {
      tabs,
      rules,
      addForm,
      visible,
      tableData,
      addStage,
      status,
      search,
      headerRows,
      queryStage,
      queryMember,
      handleMemberSelect,
      handleStageSelect,
      submit,
      handleStartPicker,
      handleCopyPlan,
      copyText,
      copyLoading,
    };
  },
});
