import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "crud-container" }
const _hoisted_2 = {
  key: 0,
  class: "crud-title"
}
const _hoisted_3 = {
  key: 1,
  class: "crud-status-bar"
}
const _hoisted_4 = { class: "crud-status-btn-list" }
const _hoisted_5 = {
  key: 2,
  class: "crud-pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_pane = _resolveComponent("filter-pane")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_table_pane = _resolveComponent("table-pane")!
  const _component_crudPagination = _resolveComponent("crudPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.options.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.options.title), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_filter_pane, {
      queryForm: _ctx.formInline,
      "onUpdate:queryForm": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formInline) = $event)),
      "filter-data": _ctx.searchOptions,
      onSearch: _ctx.search
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.searchTransferSlots, (key) => {
        return {
          name: key,
          fn: _withCtx((formInlineProps) => [
            _renderSlot(_ctx.$slots, key, {
              props: formInlineProps.props
            }, undefined, true)
          ])
        }
      })
    ]), 1032, ["queryForm", "filter-data", "onSearch"]),
    (_ctx.showTabs)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.tabsOptions?.length > 0)
            ? (_openBlock(), _createBlock(_component_el_tabs, {
                key: 0,
                modelValue: _ctx.formInline.status,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formInline.status) = $event)),
                class: "crud-tabs",
                type: "card",
                onTabChange: _ctx.refresh
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsOptions, (tab) => {
                    return (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: tab.value,
                      label: tab.label,
                      name: tab.value
                    }, null, 8, ["label", "name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "onTabChange"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "crudStatusBtn", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_table_pane, _mergeProps(_ctx.$attrs, {
      data: _ctx.tableData,
      "table-options": _ctx.tableOptions
    }), _createSlots({ _: 2 }, [
      _renderList(_ctx.tableTransferSlots, (key) => {
        return {
          name: key,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, key, {
              row: scope.row,
              $index: scope.$index
            }, undefined, true)
          ])
        }
      })
    ]), 1040, ["data", "table-options"]),
    (_ctx.pagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_crudPagination, {
            formInline: _ctx.formInline,
            "onUpdate:formInline": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formInline) = $event)),
            total: _ctx.total,
            onPageChange: _ctx.pageChange
          }, null, 8, ["formInline", "total", "onPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}